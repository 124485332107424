<template>
  <div
    id="users"
    class="page-container-table"
  >
    <button-all-header
      :contentBtnAdd="'Thêm thiết bị'"
      :hideImportFile="false"
      :hideExportFile="false"
      :hideDowload="false"
      :arrayExcel="[]"
      :showBtnMultiDelete="showBtnMultiDelete"
      @clickDelete="deleteItems"
      @clickDowloadSample="() => {}"
      @clickExportExcel="() => {}"
      @importFile="() => {}"
      @clickAdd="showModalCreate"
      @search="search($event)"
    />

    <vue-good-table
      ref="user-table"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      @on-selected-rows-change="selectRowTable"
    >
      <!-- Slot: Table Row -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column:Mã người dùng -->
        <span
          v-if="props.column.field === 'code'"
          class="text-nowrap"
        >
          <b-badge
            pill
            class="light-code"
          >
            <span class="text-name">{{ props.row.code }}</span>
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'dateDeclare'">
          <span>
            {{ props.row.dateDeclare | formatDateToDDMM }}
          </span>
        </span>
        <span v-else-if="props.column.field === 'dateReceive'">
          <span>
            {{ props.row.dateReceive | formatDateToDDMM }}
          </span>
        </span>
        <span v-else-if="props.column.field === 'declareEquipmentStatusString'">
          <b-badge
            pill
            :variant="statusVariantType(props.row.declareEquipmentStatus)"
            class="border-status"
          >
            {{ props.row.declareEquipmentStatusString }}
          </b-badge>
        </span>

        <!-- Column:Chức năng -->
        <span v-else-if="props.column.field === 'ActionFunction'">
          <span
            @click="showModalPDF(props.row.id)"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Phiếu khai báo thiết bị'"
              icon="EyeIcon"
              size="18"
              class="text-body"
            />
          </span>
          <span
            class="ml-2"
            @click="showModalEdit(props.row.id)"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Chỉnh sửa'"
              icon="EditIcon"
              size="18"
              class="text-body"
            />
          </span>
          <span
            class="ml-2"
            @click="deleteItem(props.row.id)"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Xóa'"
              icon="Trash2Icon"
              size="18"
              class="text-body"
            />
          </span>
        </span>
      </template>
    </vue-good-table>

    <!-- pagination -->
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="pageChange"
    />

    <modal-see-pdf
      :id="modalSeePdf"
      :pdfsrc="pdfsrc"
      @downloadFile="downloadFilePdf"
    />

    <!-- modal xóa người dùng-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />

  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import { mapActions, mapGetters } from 'vuex'
import {
  BBadge,
  BMedia,
  BDropdown,
  BDropdownItem,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import UserAvatar from '@/components/avatar/UserAvatar.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Search from '@/components/search/Search.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import ModalSeePdf from './pages/components/ModalSeePdf.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from './constants/ConstantsApi'

export default {
  components: {
    VueGoodTable,
    BBadge,
    BMedia,
    MyPagination,
    UserAvatar,
    BDropdown,
    BDropdownItem,
    FeatherIcon,
    Search,
    ButtonComponent,
    ConfirmModal,
    BButton,
    VBTooltip,
    // ModalCreate,
    ButtonAllHeader,
    ModalSeePdf,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      formatFullName,
      columns: [
        {
          label: 'MÃ KHAI BÁO',
          field: 'code',
          sortable: false,
        },
        {
          label: 'NGƯỜI KHAI BÁO',
          field: 'userDeclare',
          sortable: false,
        },
        {
          label: 'NGÀY KHAI BÁO',
          field: 'dateDeclare',
          sortable: false,
        },
        {
          label: 'NGÀY HẸN TRẢ',
          field: 'dateReceive',
          sortable: false,
        },
        {
          label: 'TRẠNG THÁI',
          field: 'declareEquipmentStatusString',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'ActionFunction',
          sortable: false,
          width: '150px',
        },
      ],
      currentPage: 0,
      urlQuery: {
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      confirmModalId: 'confirm-modal',
      modalContent: '',
      showBtnMultiDelete: false,
      deleteIds: [],
      isActive: false,
      modalType: '',
      totalRecord: 0,
      userId: '',
      dataDetailInjury: {},
      injuryId: '',
      modalIdCreate: 'modalIdCreate',
      modalIdEdit: 'modalIdEdit',
      dataList: [],
      pdfsrc: null,
      modalSeePdf: 'comfirm-pdf-license  ',
      idDetailPdf: '',
      urlApi: '/DeclareEquipment/file-declare-equipment',

      // dataList: dataFake.data,
    }
  },
  computed: {
    statusVariantType() {
      const typeColor = {
        Reject: 'danger',
        NotApprove: 'secondary',
        Approve: 'primary',
      }
      return color => typeColor[color]
    },
  },
  created() {
    this.fetchData(this.urlQuery)
  },
  methods: {
    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },
    // xóa 1 người dùng
    deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xoá người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_DECLARE, this.deleteIds).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchData(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // click show modal thêm vs xóa
    showModalCreate() {
      this.modalType = 'add'
      this.$router.push({ name: 'declare-equipment-add' })
    },
    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // Show edit
    showModalEdit(id) {
      this.$router.push({ name: 'declare-equipment-edit', params: { id } })
    },

    // Fetch data
    async fetchData(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.API_DECLARE, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // Lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchData(this.urlQuery)
      }
    },

    showModalPDF(id) {
      this.idDetailPdf = id
      axiosApiInstance({
        url: this.urlApi, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { id },
      }).then(response => {
        const blob = new Blob([response.data])
        const objectUrl = URL.createObjectURL(blob)
        this.pdfsrc = objectUrl
      })
      this.$bvModal.show(this.modalSeePdf)
    },

    downloadFilePdf() {
      axiosApiInstance({
        url: this.urlApi, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { id: this.idDetailPdf },
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'KhaiBaoThietBi.pdf') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },

  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

#users {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }

  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }

  .search-input {
    width: 400px;
  }

  .button-import {
    .input-file {
      display: none;
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }
}
</style>
